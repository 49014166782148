import React from 'react'
import cx from 'classnames'

interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    label?: string;
    placeholderClass?: string;
    changeHandler: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextArea: React.FC<TextAreaProps> = ({
    label,
    name,
    value,
    placeholder,
    placeholderClass,
    rows = 4,
    disabled = false,
    className,
    changeHandler,
}) => (

    <div className={cx('inline-flex text-semibold border-b border-dashed', className)}>
        {label && <span className="inline-flex pr-4 text-gallery" style={{ marginRight: 2 }}>{label}:</span>}
        <textarea
            {...{
                type: 'text',
                name,
                placeholder,
                disabled,
                rows,
                className: cx('flex-1 bg-transparent outline-none resize-none', placeholderClass, { disabled }),
                onChange: (e) => changeHandler(e),
            }}
        >
            {value}
        </textarea>
    </div>
)

export default TextArea
