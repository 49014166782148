import React from 'react'

import { BrowserProvider, useBrowserState } from './BrowserProvider'
import { MenuProvider, useMenuState } from './MenuProvider'

const ContextProvider: React.FC = ({
    children,
}) => (
    <BrowserProvider>
        <MenuProvider>
            {children}
        </MenuProvider>
    </BrowserProvider>
)

export {
    ContextProvider,
    useBrowserState,
    useMenuState,
}
