import { BaseComponentProps } from '@/types';
import React from 'react';

import SVG from 'react-inlinesvg';

import svgs from './svgs';

type IconProps = {
    name?: string;
    src?: string;
    className?: string;
    alt?: string;
}

const Icon: React.FC<IconProps> = ({
    name,
    src = null,
    className
}) => (name || src) && (
    <SVG src={src || svgs[name]} {... { className }} />
)

export default Icon;