import React, { FC, useState } from "react"
import cs from "classnames"

import Cookies from "js-cookie"

import Button from "@/components/Button"
import Toggle from "@/components/Inputs/Toggle"

import "./styles.scss"

interface ICookieSettingsProps {
  handleAccept: () => void
  handleDecline: () => void
  initTracking: () => void
  handleCloseAll: () => void
}

const CookieSettings: FC<ICookieSettingsProps> = ({
  handleAccept,
  handleDecline,
  initTracking,
  handleCloseAll,
}) => {
  const [trackAnalytics, setTrackAnalytics] = useState(
    Cookies.get("gatsby-gdpr-google-analytics") === "true"
  )

  const handleToggle = () => {
    Cookies.set("gatsby-gdpr-responded", true, { expires: 365 })

    setTrackAnalytics((prevState) => {
      if (prevState) {
        Cookies.remove("gatsby-gdpr-google-analytics")
      } else {
        Cookies.set("gatsby-gdpr-google-analytics", true, { expires: 365 })
      }

      return !prevState
    })

    initTracking()
  }

  return (
    <div className="cookie-settings w-full sm:w-96 h-full p-8 fixed top-0 left-0 bg-white shadow-2xl overflow-y-scroll z-50">
      <h3 className="line-styled-heading mb-4 text-2xl font-extrabold uppercase">
        This site uses cookies to store information on your computer.
      </h3>
      <div className="mb-4 text-sm">
        <p>
          Some of these cookies are essential, while others help us to improve
          your experience by providing insights into how the site is being used.
        </p>
        <p className="mt-4">
          You can find out how to manage your preferences in relation to our use
          of cookies at Cookie Policy
        </p>
      </div>
      <div className="flex items-center">
        <Button size="sm" clickHandler={() => handleAccept()}>
          Accept
        </Button>
        <Button size="sm" clickHandler={() => handleDecline()} className="ml-4">
          Decline
        </Button>
      </div>
      <div className="pt-8 mt-8 border-t border-dashed border-aldo">
        <div className="mb-4 flex items-center justify-between">
          <h4 className="text-lg font-extrabold uppercase">
            Necessary Cookies
          </h4>
        </div>
        <p className="text-sm">
          Necessary cookies enable core functionality such as page navigation
          and access to secure areas. The website cannot function properly
          without these cookies, and can only be disabled by changing your
          browser preferences.
        </p>
      </div>
      <div className="pt-8 mt-8 border-t border-dashed border-aldo">
        <div className="mb-4 flex items-center justify-between">
          <h4 className="text-lg font-extrabold uppercase">
            Analytical Cookies
          </h4>
          <Toggle active={trackAnalytics} toggleActive={() => handleToggle()} />
        </div>
        <p className="text-sm">
          Analytical cookies are non-essential cookies which help us to improve
          our website by collecting and reporting information on its usage. We
          use these cookies so we can better understand how you engage with our
          website, marketing campaigns and products. The information we collect
          is anonymised.
        </p>
      </div>
      <Button
        size="sm"
        color="black-border"
        clickHandler={() => handleCloseAll()}
        className="mt-8 md:mt-12"
      >
        Close
      </Button>
    </div>
  )
}

export default CookieSettings
