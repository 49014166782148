import React, { useState, useEffect, FC } from "react"

import { useLocation } from "@reach/router"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import Cookies from "js-cookie"

import Button from "@/components/Button"
import CookieSettings from "./Settings"

import "./styles.scss"

const CookieBanner: FC<any> = () => {
  const [showBanner, setShowBanner] = useState(false)
  const [showSettings, setShowSettings] = useState(false)
  const location = useLocation()

  useEffect(() => {
    setShowBanner(Cookies.get("gatsby-gdpr-responded") !== "true")
  }, [])

  useEffect(() => {
    initTracking()
  }, [Cookies.get("gatsby-gdpr-responded")])

  const initTracking = () => {
    initializeAndTrack(location)
  }

  const handleAccept = () => {
    Cookies.set("gatsby-gdpr-google-analytics", true, { expires: 365 })
    handleCloseAll()
  }

  const handleDecline = () => {
    Cookies.remove("gatsby-gdpr-google-analytics")
    handleCloseAll()
  }

  const handleCloseAll = () => {
    setShowSettings(false)
    setShowBanner(false)

    Cookies.set("gatsby-gdpr-responded", true, { expires: 365 })
  }

  return (
    <>
      <div
        className={`cookie-banner ${
          showBanner && !showSettings ? "block" : "hidden"
        } w-full p-8 fixed bottom-4 sm:bottom-8 right-4 sm:right-8 text-center md:text-left shadow-xl z-50`}
      >
        <p className="mb-4 text-sm">
          Our site uses cookies and similar technologies. Some are essential and
          some improve the experience. Please click ‘accept’ to accept all
          cookies. Please click ‘decline’ to keep all cookies off except those
          that are necessary.
        </p>
        <div className="flex flex-col md:flex-row items-center">
          <div className="flex items-center">
            <Button
              clickHandler={() => handleAccept()}
              color="primary"
              size="sm"
            >
              Accept
            </Button>
            <Button
              clickHandler={() => handleDecline()}
              size="sm"
              color="secondary-border"
              className="ml-4"
            >
              Decline
            </Button>
          </div>
          <Button
            color="link"
            size="sm"
            clickHandler={() => setShowSettings(true)}
            className="mt-4 md:mt-0 md:ml-4"
          >
            Configure
          </Button>
        </div>
      </div>
      {showSettings && (
        <CookieSettings
          {...{ handleAccept, handleDecline, initTracking, handleCloseAll }}
        />
      )}
    </>
  )
}

export default CookieBanner
