import React, { createContext, useState, useContext } from 'react'
import { isMobileOnly, isMobile, isTablet, isIE } from 'react-device-detect'

interface BrowserContextProps {
	browser: string;
	themeSettings: ThemeSettings
}

interface ThemeSettings {
	isMobile: boolean;
	isMobileOnly: boolean;
	isTablet: boolean;
}

const BrowserContext = createContext<Partial<BrowserContextProps>>({})

/**
 * BrowserProvider
 * 
 * @param {mixed} children
 */
const BrowserProvider: React.FC = ({ children }) => {
	const [browser] = useState<string>(isIE ? 'ie' : 'modern')
	const [themeSettings] = useState<ThemeSettings>({ isMobile, isMobileOnly, isTablet })

	return (
		<BrowserContext.Provider value={{ browser, ...themeSettings }}>
			{children}
		</BrowserContext.Provider>
	)
}

/**
 * useBrowserState
 */
const useBrowserState = () => {
	const context = useContext(BrowserContext)

	if (context === undefined) throw new Error('useBrowserState must be used within a BrowserProvider')

	return context
}

export { BrowserProvider, useBrowserState }
