import React, { useState } from 'react'
import cx from 'classnames'

import Icon from '../../Icon'

import './styles.scss'

interface SelectProps {
    value: string,
    color?: string,
    size?: string;
    setValue: (e) => void;
    options?: OptionProps[];
    className?: string;
}

type OptionProps = {
    value: string;
    label: string;
}

const Select: React.FC<SelectProps> = ({
    value,
    color = 'light',
    size = 'md',
    setValue,
    options,
    className,
}) => {
    const [ open, setOpen ] = useState<boolean>(false)

    return options.length > 0 && (
        <div className={cx(`select flex items-center justify-between relative border-b border-dashed ${color === 'light' ? 'border-black' : 'border-white'} cursor-pointer size-${size} select-${color} z-20`, className)} onClick={() => setOpen(!open)}>
            <div className={cx('select-none', { 'py-4 text-2xl md:text-3xl': (size === 'lg'), 'text-base': (size === 'md') })}>{options?.find((option) => value === option?.value)?.label || options[0].label}</div>
            <Icon name="angleDown" className={`transform origin-center ${open ? 'rotate-180' : 'rotate-0'}`} />
            <div className={`select__options absolute w-full top-full left-0 ${open ? 'block' : 'hidden'}`}>
                <div className="select__shadow absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-3xl" />
                {options.map((option, i) => (
                    <div key={i} className={cx(`select__option flex items-center ${(size === 'lg') ? 'h-14 px-4' : 'h-10 px-2'} relative text-black hover:text-secondary`, { 'text-secondary font-bold active': value === option?.value})} onClick={() => setValue(option.value)}>
                        <span className="relative z-20">{option?.label}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Select
