import React from 'react';

import cx from 'classnames';

interface InputTextProps extends React.InputHTMLAttributes<HTMLInputElement> {
    placeholderClass?: string;
    className?: string;
    changeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
    label?: string;
}

const Text: React.FC<InputTextProps> = ({
    label,
    name,
    value,
    placeholder,
    placeholderClass,
    disabled = false,
    required = false,
    className,
    changeHandler,
}) => (
    <div className={cx('inline-flex text-semibold border-b border-dashed', className)}>
        {label && <span className="inline-flex pr-4 text-gallery" style={{ marginRight: 2 }}>{label}:</span>}
        <input
            {...{
                type: 'text',
                name,
                value,
                placeholder,
                disabled,
                required,
                className: cx('flex-1 bg-transparent outline-none', placeholderClass, { disabled }),
                onChange: (e) => changeHandler(e),
            }}
        />
    </div>
)

export default Text