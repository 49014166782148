import React from "react"

import logo from "@/images/logo-white.svg"

import Link from "@/components/Link"
import Icon from "@/components/Icon"
import LinkWithIcon from "@/components/LinkWithIcon"
import MailchimpForm from "@/components/MailchimpForm"
import ContactForm from "@/components/ContactForm"

export const Footer: React.FC = () => {
  const date = new Date()

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  return (
    <footer id="footer" className="py-12 md:py-16 xl:py-28 bg-black">
      <div className="container mx-auto px-10">
        <div className="grid grid-cols-1 md:grid-cols-14 gap-10">
          <div className="col-span-full lg:col-span-7 xl:col-span-6 xl:col-start-2">
            <h3
              className={
                "line-styled-heading text-white mb-8 md:mb-12 text-4xl md:text-3xl lg:text-4xl xl:text-5xl font-bold uppercase"
              }
            >
              Sign up to join <span className="block">our community</span>
            </h3>
            <MailchimpForm />
            <div className="flex items-center text-white mt-8 md:mt-12">
              <a
                href="https://twitter.com/ndreams"
                rel="noopener noreferrer"
                target="_blank"
                className="hover:text-red transition duration-200 w-6"
              >
                <Icon name="twitter" />
                <span className="hidden">Twitter</span>
              </a>
              <a
                href="https://www.youtube.com/channel/UCI5twGy4kDS1xf141wZP5jg"
                rel="noopener noreferrer"
                target="_blank"
                className="ml-4 lg:ml-6 hover:text-red transition duration-200"
              >
                <Icon name="youtube" />
                <span className="hidden">YouTube</span>
              </a>
              <a
                href="https://www.facebook.com/nDreamsVR/"
                rel="noopener noreferrer"
                target="_blank"
                className="ml-4 lg:ml-6 hover:text-red transition duration-200"
              >
                <Icon name="facebook" />
                <span className="hidden">Facebook</span>
              </a>
              <a
                href="https://www.instagram.com/ndreams_vr/?hl=en"
                rel="noopener noreferrer"
                target="_blank"
                className="ml-4 lg:ml-6 hover:text-red transition duration-200"
              >
                <Icon name="instagram" />
                <span className="hidden">Instagram</span>
              </a>
              <a
                href="https://discord.com/invite/nDreams"
                rel="noopener noreferrer"
                target="_blank"
                className="ml-4 lg:ml-6 hover:text-red transition duration-200 w-6"
              >
                <Icon name="discord" />
                <span className="hidden">Discord</span>
              </a>
            </div>
          </div>
          <div className="col-span-full lg:col-span-7 xl:col-span-6">
            <h3
              className={
                "line-styled-heading text-white mb-8 md:mb-12 text-4xl md:text-3xl lg:text-4xl xl:text-5xl font-bold uppercase"
              }
            >
              Get in <span className="block">touch</span>
            </h3>
            <ContactForm />
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-1 xl:grid-cols-14">
          <div className="lg:col-span-14 xl:col-span-12 xl:col-start-2 mt-8 md:mt-12 pt-12 md:pt-16 border-t border-dashed border-white">
            <div className="lg:grid lg:grid-cols-3 lg:col-span-full xl:col-span-12 xl:col-start-2">
              <div className="flex flex-col justify-center items-center lg:items-start text-sm text-boulder">
                <a href="https://ndreams.com" rel="noreferrer noopenner">
                  <img
                    src={logo}
                    alt="nDreams"
                    className="w-30 block mb-4 lg:mb-6"
                  />
                </a>
                <p>&copy;{date.getFullYear()} nDreams. All Rights Reserved</p>
              </div>
              <div className="flex flex-col justify-center items-center mt-8 lg:mt-0 text-sm text-boulder text-center">
                <p>
                  nDreams Limited, Spectrum Point, 279 Farnborough Road,
                  Farnborough, Hampshire, GU14 7LS, England, UK
                </p>
                <ul className="flex items-center justify-center mt-8 font-bold">
                  <li className="ml-4 hover:text-red transition duration-200">
                    {/* <Link
                      to="https://ndreams.com/privacy-policy"
                      external={true}
                    >
                      Privacy Policy
                    </Link> */}
                    <Link to="https://ndreams.com/terms" external={true}>
                      Legal
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="flex flex-col justify-center items-center lg:items-end mt-8 lg:mt-0">
                <LinkWithIcon
                  color="mi-light"
                  text="Back to top"
                  icon="arrowUp"
                  clickHandler={scrollToTop}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
