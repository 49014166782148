import React from 'react'
import cx from 'classnames'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    size?: string,
    clickHandler: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
    children?: React.ReactNode
}

const Button: React.FC<ButtonProps> = ({
    type = 'button',
    color = 'primary',
    size = 'md',
    clickHandler,
    className,
    children,
}) => (clickHandler && children) && (
    <button
        {...{
            type,
            onClick: clickHandler,
            className: cx('inline-flex items-center font-bold uppercase cursor-pointer border-2 border-solid outline-none transition duration-200', className, {
                'py-2 px-2 md:px-4 text-sm': size === 'sm',
                'py-2 px-4 md:px-6 text-lg': size === 'md',
                'bg-primary border-primary text-white': color === 'primary',
                'bg-secondary border-secondary text-white': color === 'secondary',
                'bg-transparent border-primary text-primary': color === 'primary-border',
                'bg-transparent border-secondary text-secondary': color === 'secondary-border',
                'bg-transparent border-black text-black': color === 'black-border',
                'bg-transparent border-transparent text-boulder font-bold uppercase': color === 'link',
            }),
        }}
    >
        {children}
    </button>
)

export default Button
