import CookieBanner from "@/components/CookieBanner"
import { Footer } from "@/components/Footer"
import Header from "@/components/Header"
import { ContextProvider } from "@/context"
import { BrowserProvider } from "@/context/BrowserProvider"
import { MenuProvider } from "@/context/MenuProvider"
import { BaseComponentProps } from "@/types"

import React from "react"

// interface LayoutProps extends BaseComponentProps {
//     pageContext: {
//         id: string;
//         slug: string;
//     }
// };

interface PageLayoutProps extends React.HTMLAttributes<HTMLDivElement> {}

export const Layout: React.FC<PageLayoutProps> = ({ children }) => {
  return (
    <ContextProvider>
      <BrowserProvider>
        <MenuProvider>
          <div className="bg-black">
            <Header />
            {children}

            <Footer />
          </div>
          <CookieBanner />
        </MenuProvider>
      </BrowserProvider>
    </ContextProvider>
  )
}
