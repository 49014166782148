import React from "react";
import cx from "classnames";
import { Link as GatsbyLink } from "gatsby";

interface LinkProps {
  to: string;
  external?: boolean;
  clickHandler?: (e: React.SyntheticEvent) => void;
  onMouseEnter?: (e: React.SyntheticEvent) => void;
  onMouseLeave?: (e: React.SyntheticEvent) => void;
  className?: string;
  newTab?: boolean;
}

const Link: React.FC<LinkProps> = ({
  to,
  children,
  external = false,
  clickHandler,
  onMouseEnter,
  onMouseLeave,
  className,
  newTab = true,
  isStudioPage = false,
}) => {
  const handleClick = (e: React.SyntheticEvent<HTMLAnchorElement>) => {
    if (typeof clickHandler === "function") {
      clickHandler(e);
      return true;
    }
  };

  if (external || typeof clickHandler === "function") {
    return (
      <a
        href={to}
        target={newTab ? "_blank" : ""}
        rel="noreferrer noopener"
        className={cx("cursor-pointer", className)}
        onClick={handleClick}
      >
        {children}
      </a>
    );
  } else {
    return (
      <GatsbyLink
        {...{
          to,
          className: cx("cursor-pointer", className),
          onMouseEnter,
          onMouseLeave,
        }}
      >
        {children}
      </GatsbyLink>
    );
  }
};

export default Link;
